import { createStore } from "vuex";
import getters from "./getters";
import mutations from "./mutations";

export default createStore({
  namespaced: true,
  state() {
    return {
      homePageHeader: {
        title1: "Learn Spanish.",
        title2: "Online.",
        desc1:
          "Our online classes with native, qualified teachers are dynamic, interactive,",
        desc2:
          "and practical. Join our group lessons today to enhance your Spanish skills.",
        desc3: "lessons.",
        button1: "Get started",
        button2: "Discover more",
        scrollElement: ".our-courses-badge",
        bg: "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/homeHeader1.png?alt=media&token=0b14d873-50df-4509-b9c5-0e20d59df9ed",
      },
      coursesHeader: {
        title1: "Courses",
        desc1: "Join us in mastering Spanish! Whether for business or",
        desc2: "casual conversations, you'll start speaking confidently",
        desc3: "from day one.",
        button1: "Get started",
        button2: "Learn more",
        scrollElement: ".our-courses-badge",
        bg: "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/courseBG.png?alt=media&token=8ae43cfb-26e0-4066-8235-cdfc78683a13",
        bgInner:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/courseBGInner.png?alt=media&token=3fd68412-9754-4ec9-a2a8-15063c9f1ec3",
        frame1:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Frame51351.png?alt=media&token=92c1bb9b-3321-4b12-b328-4ed2cb49a795",
        frame2:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Frame51352.png?alt=media&token=2ad59530-a764-4e0e-9af3-67342f471b60",
        frame3:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Frame51353.png?alt=media&token=8a749251-6f53-48d7-8d8b-48b261baae78",
      },
      K12Header: {
        title1: "K-12 schools",
        desc1: "Empowering Student Success",
        desc4:
          "Comligo drives student success with cutting-edge educational tools that boost learning outcomes and support academic excellence from Elementary to High School students.",
        button1: "Get started",
        button2: "Learn more",
        scrollElement: ".our-solutions-badge",
        bg: "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/courseBG.png?alt=media&token=8ae43cfb-26e0-4066-8235-cdfc78683a13",
        bgInner2:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/k12.png?alt=media&token=0b0c43ab-7e23-4d16-85f6-1ad20425240d",
        frame5:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/k12BG.png?alt=media&token=e63f61d4-f62f-42d0-99ad-779ff39afc77",
      },
      AboutUsHeader: {
        title1: "About Us",
        desc1:
          "Join us on a journey to promote Spanish language and culture across the globe.",
        button1: "Get started",
        button2: "Learn more",
        bgInner3:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/plant.png?alt=media&token=bfb654ef-5d3d-41e9-a20c-d7010f03c194",
        scrollElement: ".our-story-title",
        bg: "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/courseBG.png?alt=media&token=8ae43cfb-26e0-4066-8235-cdfc78683a13",
        frame5:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/aboutus.png?alt=media&token=2a40a1b2-52d3-46ad-acf6-5238730a6db9",
      },
      IBHeader: {
        title1: "IB Schools",
        desc1: "Empowering Global Learners",
        desc4:
          "Comligo elevates the IB (International Baccalaureate) experience by providing tailored language programs that promote linguistic diversity and academic excellence. Our solutions are designed to help IB students thrive in a globalized world, ensuring they excel in their international education.",
        button1: "Get started",
        button2: "Learn more",
        scrollElement: ".our-solutions-badge",
        bg: "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/courseBG.png?alt=media&token=8ae43cfb-26e0-4066-8235-cdfc78683a13",
        bgInner2:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/IBschools.png?alt=media&token=c2b383f5-a501-48bb-a958-0665fe8cbc4b",
        frame5:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/IBschoolsBG.png?alt=media&token=e7aeb340-b309-4580-8751-f3bd613d6766",
      },
      higherEducationHeader: {
        title1: "Higher education",
        desc1: "Empowering Academic Excellence",
        desc4:
          "Comligo enhances the higher education experience by delivering specialized language programs that support academic success and global competence. Our tailored solutions are designed to meet the diverse needs of students and faculty, preparing them for a thriving career in a globalized world.",
        button1: "Get started",
        button2: "Learn more",
        scrollElement: ".our-solutions-badge",
        bg: "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/courseBG.png?alt=media&token=8ae43cfb-26e0-4066-8235-cdfc78683a13",
        bgInner2:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/higherEducation.png?alt=media&token=f7c43227-3201-47f0-b0b3-429c49445a11",
        frame5:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/higherEducationBG.png?alt=media&token=1899f41f-8a64-4e78-a545-e28acc232fdc",
      },
      privateSectorHeader: {
        title1: "Private sector",
        desc1: "Strengthening Communities Through Language",
        desc4:
          "Comligo partners with private sector organizations and NGOs to deliver tailored language programs that enhance community engagement, promote cultural awareness, and improve access to essential services and resources. Our offerings help businesses and NGOs foster a more inclusive environment and address the diverse needs of the communities they serve.",
        button1: "Get started",
        button2: "Learn more",
        scrollElement: ".our-solutions-badge",
        bg: "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/courseBG.png?alt=media&token=8ae43cfb-26e0-4066-8235-cdfc78683a13",
        bgInner2:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/privateSector.png?alt=media&token=b2c1d092-2506-4a6d-bddd-f49bd003d336",
        frame5:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/provateSectorBG.png?alt=media&token=07d1496d-235a-462b-80ec-796fe398c088",
      },
      corporateSectorHeader: {
        title1: "Corporate Organizations",
        desc1: "Empowering Workforce Excellence",
        desc4:
          "Comligo enhances professional growth by offering Spanish language programs tailored to meet the diverse needs of corporate organizations. These programs equip staff with essential language skills, promoting effective communication and cultural awareness within the workplace.",
        button1: "Get started",
        button2: "Learn more",
        scrollElement: ".our-solutions-badge",
        bg: "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/courseBG.png?alt=media&token=8ae43cfb-26e0-4066-8235-cdfc78683a13",
        bgInner2:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/privateSector.png?alt=media&token=b2c1d092-2506-4a6d-bddd-f49bd003d336",
        frame5:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/provateSectorBG.png?alt=media&token=07d1496d-235a-462b-80ec-796fe398c088",
      },
      nonProfitsHeader: {
        title1: "Public and non-profits",
        desc1: "Empowering Communities Through Language",
        desc4:
          "Comligo collaborates with public sector organizations to deliver tailored language programs that enhance community engagement, promote cultural awareness, and improve access to essential services and resources. Our offerings help public agencies foster a more inclusive environment and address the diverse needs of the communities they serve.",
        button1: "Get started",
        button2: "Learn more",
        scrollElement: ".our-solutions-badge",
        bg: "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/courseBG.png?alt=media&token=8ae43cfb-26e0-4066-8235-cdfc78683a13",
        bgInner2:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/nonProfits.png?alt=media&token=7455cda9-4b56-4237-ae11-4ce8c844473c",
        frame5:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/nonProfitsBG.png?alt=media&token=d2639db9-b62d-4a56-a95f-0eee7b94d90f",
      },
      K12ProviderHeader: {
        title1: "DOE-Approved K-12 Programs",
        desc1:
          "Empower your students' education with flexible accredited courses",
        button1: "Get started",
        button2: "Learn more",
        scrollElement: ".our-solutions-badge",
        bg: "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/courseBG.png?alt=media&token=8ae43cfb-26e0-4066-8235-cdfc78683a13",
        bgInner5:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/k12providersmap.png?alt=media&token=2261a9c0-3468-4d63-801e-9e87494d10b3",
      },
      resourcesHeader: {
        title1: "Resources",
        desc1:
          "Join us on a journey to promote Spanish language and culture across the globe.",
        button1: "Get started",
        button2: "Learn more",
        scrollElement: ".our-solutions-badge",
        bg: "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/courseBG.png?alt=media&token=8ae43cfb-26e0-4066-8235-cdfc78683a13",
        bgInner2:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/resourcesImg.png?alt=media&token=a240b149-e0c1-4d24-b0cd-59ae168395bf",
        frame5:
          "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/resourcesBG.png?alt=media&token=c2dd6edd-1d11-418b-a7e0-00c2e18ca402",
      },
      missionsAndVisions: [
        {
          icon: "mdi mdi-rocket-launch",
          title: "Mission",
          description:
            "Our mission is to promote Spanish study and teaching worldwide, fostering a global community of learners and enthusiasts, making high-quality education accessible to everyone.",
        },
        {
          icon: "mdi mdi-eye-outline",
          title: "Vision",
          description:
            "Our vision is to create a cohesive world through Spanish and respectful coexistence with other cultures. We strive to lead in innovative language education, bridging cultural gaps and fostering understanding.",
        },
        {
          icon: "mdi mdi-hand-heart-outline",
          title: "Values",
          description:
            "We value inclusivity by making education accessible globally. We promote intercultural awareness, prioritize personalized support, and advocate for diversity in all our educational initiatives.",
        },
      ],
      meetOurTeam: {
        academicTeam: {
          description:
            "Our academic team is composed of dedicated educational experts committed to providing high-quality Spanish language content and personalized support to students.",
          description1:
            "We have developed a framework to ensure academic quality. An experienced team provides guidance, quality assurance, and processes that ensure the effective implementation of the framework's elements.",
          list: [
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/teamthumb1.png?alt=media&token=e068aea3-11af-4956-8120-9a84ba340a14",
              title: "MARTA PRIETO RIVAS",
              description:
                "Hello! My name is Marta.I am from Spain,and at present, I live in Hong Kong. I hold a Master in Audiovisual Journalism and I am a certified DELE examiner. I combine a solid academic background with entrepreneurial experience which led me to set up and run a very successful Spanish school for young learners, De La Mano Spanish, in Hong Kong. I now work at Comligo as Director of International Relationships.",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/teamthumb6.png?alt=media&token=b48bb3f5-3b36-4c80-989a-f5ba62e3277f",
              title: "JAIRO PÉREZ",
              description:
                "Hello! My name is Jairo and I am Mexican. Currently, I live in Montevideo, Uruguay and I work at Comligo Spanish as academic coordinator. I have worked as a teacher in the areas of Human and Artistic Sciences as well as Spanish as a second language. I am a graduate of the National Autonomous University of Mexico. I am currently finishing a master's at the Universidad de la República in Uruguay. I enjoy reading, art, travel, getting to know different cultures, visiting museums, enjoying nature and swimming.",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/teamthumb2.png?alt=media&token=4fa3c1e9-d79d-4680-af36-fe032cbe2d6a",
              title: "PAMELA LEONARD",
              description:
                "I'm Pamela Leonard, based north of Dallas, where I teach virtual Spanish classes at Collin College and work with Comligo’s academic team. Originally from California, I’ve taught Spanish for over 20 years, fueled by a passion sparked during a high school trip to Mexico. I hold a master’s in Spanish Language and Linguistics from the University of Arizona. I enjoy watching Spanish films, visiting art museums, and listening to Latin music.",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Diego%20G.jpeg?alt=media&token=3dc3d293-c0b3-40d7-b46e-8f7b7dadf7f6",
              title: "DIEGO PEREIRA",
              description:
                "I'm Diego Pereira, I am a Academic Operation Coordinator",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Julia_Bracelis.png?alt=media&token=0ec70781-cbc7-402c-9e9a-c622139a3639",
              title: "JULIA BRACELIS",
              description: "I'm Julia Bracelis, I am a teacher at comligo",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/teacherlogo.png?alt=media&token=fd6f06c4-1176-453c-9084-d6a23fa6316a",
              title: "LUIS PERAZA",
              description: "I'm Luis Peraza, I am an academic assistant",
              readMore: false,
            },
          ],
        },
        teachers: {
          title: "Comligo Spanish Teachers",
          description:
            "Our teachers are highly qualified and passionate educators who will be by your side to help you succeed in your Spanish goals.",
          title1: "Our Teaching Standards",
          standards: [
            "Teacher selection follows a careful recruitment process to ensure teachers meet our teaching standards",
            "All our teaching materials share an evidence-based pedagogical design that revolves around three core principles",
            "Live classes with native qualified teachers",
            "Teaching materials",
            "Audiovisual materials such as videos and podcast",
            "Text such as blogs and articles",
            "Progress reports",
            "Personalized feedback",
            "And much more",
          ],
          list: [
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/teacherlogo.png?alt=media&token=fd6f06c4-1176-453c-9084-d6a23fa6316a",
              title: "ANGELLIC ROMERO",
              description:
                "My name is Stephanie Angellic Mexico. I am passionate about education and I have the privilege of being a virtual teacher since 2018 and a language teacher since 2016. My academic journey began at the Universidad Autónoma del Estado de México, where I obtained a bachelor's degree. Then, I decided to continue my studies and graduated with a Master's Degree in Education with a focus on Educational Innovation and Technology from the Universidad del Valle de México. Currently, I have a PhD in Human Development from IEXPRO University.",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Andrea_Cubillos.jpg?alt=media&token=49bb8d0a-52dd-4ac9-8b85-1c828d89f724",
              title: "ANDREA CUBILLOS",
              description:
                "Hello, my name is Andrea Martínez Cubillos and I am from Bogotá, Colombia. For 22 years I have had the joy of doing what I love the most, teaching Spanish to students of all ages. I have a bachelor's degree in Spanish and English, a specialization in Applied Linguistics for Second Language Teaching and I recently completed a master's degree in Educational Technology and Digital Competencies. During my career in education, I have attended seminars and workshops on pedagogy and teaching Spanish as a second language (ELE).",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/teacherThumb1.png?alt=media&token=94a46593-311c-4d16-bf07-91d13bed2a7e",
              title: "GABRIELA NESTARES",
              description:
                "Hello! My name is Gabriela Nestares and I am from Rosario, Argentina. I have been teaching at the primary, secondary and university level for 25 years and I have been working as a Spanish as a Second Language Teacher since 2019. I am a Primary School Teacher and I have a degree in Anthropology, graduated from the National University of Rosario. I am also a certified teacher of Spanish as a foreign language. Traveling and getting to know other cultures is one of my favorite activities.",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/teacherThumb2.png?alt=media&token=3929f406-6f38-4c81-88e6-60f296fc83c2",
              title: "GABRIELA SERRANO",
              description:
                "I am Gabriela Guanajuato. I am 37 years old. My teaching experience began in 2007 doing my College internship in daycare centers. After finishing my Bachelor's Degree in Education, I worked as a businesswoman for 6 years as director and owner of a daycare center. After concluding that period I continued working as a teacher of English at various preschool, elementary,middle and high school levels, working in private schools and giving private lessons. I continued in a military high school teaching English and philosophy.",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/teacherThumb3.png?alt=media&token=583bd252-536e-40b5-b101-d25690beaf06",
              title: "GADI REYNA MIRANDA",
              description:
                "Hello, my name is Gadi Reyna, I am from Xochimilco in Mexico City and I live in Coyoacán. I have a master's degree in Information and Communication Design. In addition, I have teaching certifications from the University of Cambridge. Professionally I have collaborated in projects related to education, culture, media and childhood for public, private, national and International institutions. For more than 10 years I have dedicated myself to teaching in the field of languages with students of different levels and ages.",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/teacherlogo.png?alt=media&token=fd6f06c4-1176-453c-9084-d6a23fa6316a",
              title: "INGRID OSPINA",
              description:
                "Hi, I'm Ingrid Ospina from Bogotá, Colombia. I started working as a Spanish teacher for foreigners four years ago. Before that I worked as a workshop leader in NGOs and with children teaching sports and artistic disciplines. I love teaching my mother tongue and being able to share my culture and that of other Spanish speaking countries where I have had the pleasure to be like Chile, Peru, Guatemala, Mexico and Spain. I studied industrial design and then visual arts. I am currently finishing my master's degree in visual poetics in Campinas, Brazil.",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Jorge_Navarro.png?alt=media&token=6cb130b1-e1ff-4c1c-a600-02c5a15aefa6",
              title: "GEORGE NAVARRO",
              description:
                "I am from Colombia, with a degree in Linguistics and Literature from the University of Cartagena. I have additional training in pedagogy and didactics for teaching Spanish as a foreign language from the Caro y Cuervo Institute in Bogotá. Over the past 10 years, I have been dedicated to teaching Spanish to foreigners, proofreading, ortho-typographic editing, and revising translations from Portuguese to Spanish. I enjoy reading stories about reincarnation, walking along the beaches of my city, and listening to music from various genres.",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Karla_Sanchez%20.jpg?alt=media&token=848f8b3f-2498-4f3a-8626-3d8b7fe84d01",
              title: "KARLA SANCHEZ",
              description:
                "Hello, my name is Karla Sanchez Camacho, I am from Mexico City and for 24 years I have been teaching languages at different levels. I am currently dedicated to teaching Spanish. I graduated with a degree in Communication Sciences but I have always felt attracted to languages, which has led me to be certified in English with the FCE and TKT by the University of Cambridge. I am certified as a DELE examiner for all six levels by the Cervantes Institute.",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Milena_Martinez.jpg?alt=media&token=bd4ad8d2-2761-41fb-8f56-0a8acfe1b1bb",
              title: "MILENA MARTINEZ",
              description:
                "Hi, I'm Milena Martínez from Colombia. I have been a Spanish teacher for more than fifteen years in various educational settings and for the last five years as an ELE teacher. I have a degree in Spanish Language and a Master's degree in Linguistics with a focus on Spanish as a Foreign Language from the University. I am a reader, a book lover, and I am interested in the study of the written word and books as vehicles to carry eternal stories.",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Sofia_Ghilioni.jpeg?alt=media&token=c791a031-e9fa-44c6-8626-53034f7abb52",
              title: "SOFIA GHILIONI",
              description:
                "Hello! My name is Sofia Ghilioni and I'm from Rosario, Argentina. Since 2016 I have been teaching Grammar, Linguistics, and Literature and I have been teaching Spanish as a second language for three years. I love music, playing the piano, studying art, and reading. I love to travel, I love to know and experience other cultures.",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/teacherThumb4.png?alt=media&token=12d0a715-3522-4708-b671-ccfdab8b45bb",
              title: "VIVIAN SALAMANCA",
              description:
                "My name is Viviana Salamanca, and I am a Colombian teacher with a degree in Modern Languages from the University of La Salle in Bogotá, Colombia. I have 11 years of professional experience teaching Spanish. From the beginning of my career, I have been passionate about education. I am particularly drawn to didactics based on project-based pedagogy and meaningful learning, as I believe that students should find purpose, enjoyment and participation in their learning.In my free time i like to interact with other cultures from my country.",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Julia_Issac.jpg?alt=media&token=0bdb9219-8f87-49bc-8a48-2e7bb83b6d14",
              title: "JULIA ISSACS",
              description: "My name is Julia Issacs.",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Andrea_Olmos.jpg?alt=media&token=5afece57-917e-4ec6-aa08-bc123615346d",
              title: "ANDREA OLMOS",
              description: "My name is Andrea Olmos.",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Betty_Ruiz.jpg?alt=media&token=a27329ac-78ee-4034-a33e-57afc024df20",
              title: "BETTIANA RUIZ",
              description: "My name is Bettiana Ruiz",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Milagros_De%20la%20Horra.jpg?alt=media&token=0a7afa55-310d-4a07-9771-6f13ed7910ec",
              title: "MILAGROS DE LA HORRA",
              description: "My name is Milagros De La Horra.",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Paloma_Morales.jpg?alt=media&token=de392b9c-cadf-4e27-a858-3713d0161142",
              title: "PALOMA MORALES",
              description: "My name is Paloma Morales.",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Screenshot%202024-10-21%20at%2011.48.35%E2%80%AFAM.png?alt=media&token=fae01556-099a-4830-8551-a3655fc33031",
              title: "TAMIA ALEMAN",
              description: "My name is Tamia Aleman.",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Jhonny_Chumbile.jpg?alt=media&token=75d604d8-234f-4c90-b4e8-a78b78be5b6c",
              title: "JHONNY CHAMBILE",
              description: "My name is Jhonny Chambile.",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Mickaela_Garofani.jpeg?alt=media&token=9be746f8-c1d4-4fde-a314-d258f42ec66b",
              title: "Mickaela Garofani",
              description: "My name is Mickaela Garofani.",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Victoria_Arroyo.jpg?alt=media&token=f750225c-d895-45e3-92df-213e0dc8251c",
              title: "Victoria Arroyo",
              description: "My name is Victoria Arroyo.",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Tamara_Martinez.jpg?alt=media&token=c10139c9-9a69-4fd8-9d6b-9d980d12238c",
              title: "Tamara Martinez",
              description: "My name is Tamara Martinez.",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Tamara_Martinez.jpg?alt=media&token=c10139c9-9a69-4fd8-9d6b-9d980d12238c",
              title: "Tamara Martinez",
              description: "My name is Tamara Martinez.",
              readMore: false,
            },
            {
              image:
                "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Alejandro_Torres.jpg?alt=media&token=f18df8fb-92cc-469a-b1d2-51d3f0457eb3",
              title: "Alejandro Torres",
              description: "My name is Alejandro Torres.",
              readMore: false,
            },
            {
              image: "",
              title: "Become the Next",
              description:
                "Passionate about teaching Spanish? We're looking for dedicated educators to join our team at Comligo. Click the button below and help us inspire learners worldwide!",
              button: "Send CV",
            },
          ],
        },
      },
      understandingCourses: [
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/courseThumb1.png?alt=media&token=e3ea491b-fe4f-460b-b671-a212d10edc23",
          title: "Spanish for K-12 Students",
          description:
            "The variety of Spanish courses for K-12 students will allow the student to learn the language while also gaining an understanding of Hispanic culture and traditions.",
          listTitle: "Skills Acquired:",
          list: [
            "Enhanced communication skills in Spanish",
            "Greater hispanic cultural awareness and understanding",
            "Improved reading and comprehension",
          ],
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/courseThumb3.png?alt=media&token=80531b58-7afe-4b39-bb6d-5dba16944073",
          title: "Spanish for Adults",
          description:
            "We offer comprehensive online Spanish classes for adults that want to learn the language while also gaining an understanding of the Hispanic culture and traditions.",
          listTitle: "Skills Acquired:",
          list: [
            "Improved reading and vocabulary",
            "Deep knowledge of Hispanic cultures",
            "Enhanced phonological awareness",
          ],
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/courseThumb2.png?alt=media&token=d6d4234b-fc49-4c13-8f51-5354dd28788f",
          title: "Spanish IB programs",
          description:
            "Our Spanish IB programs(PYP,MYP and Diploma) focus on the Spanish language, literature, and culture. These programs foster global awareness, and effective communication skills.",
          listTitle: "Skills Acquired:",
          list: [
            "Enhanced reading and vocabulary",
            "In-depth cultural knowledge",
            "Advanced phonological awareness",
          ],
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/courseThumb4.png?alt=media&token=8e6bb5c4-8d13-4281-8a9b-8c320d56bd7a",
          title: "Professional Development in Spanish",
          description:
            "Our professional development courses aim to provide language teachers with skills necessary to advance their professional development.",
          listTitle: "Skills Acquired:",
          list: [
            "Improved professional vocabulary and communication",
            "Cultural competence in Hispanic business contexts",
            "Advanced language skills",
          ],
        },
      ],
      ourSolutionsHome: {
        tag: "Our solution",
        title1: "Big or small, grow with Comligo",
        solutions: [
          {
            bold: "Customized Spanish Courses:",
            text: "Tailored to fit the unique needs of your institution.",
          },
          {
            bold: "Engaging Instruction:",
            text: "Learn from native Spanish-speaking teachers from various Hispanic countries.",
          },
          {
            bold: "Flexible and scalable:",
            text: "Our schedules and programs adapt to any class size or need.",
          },
          {
            bold: "Ongoing Support:",
            text: "Continuous assistance and resources to ensure successful language learning and integration.",
          },
        ],
        slogan1: "CONNECT",
        slogan2: "LEARN",
        slogan3: "GROW",
      },
      ourSolutionsK12: {
        tag: "Our solution",
        title1: "Courses offered for K-12 schools",
        solutions: [
          {
            bold: "",
            text: "Curriculum (including Dual Language programs) and enrichment programs for K-12 students that are expertly designed to boost student engagement and academic achievement.",
          },
          {
            bold: "",
            text: "Spanish classes for school staff and English speaking parents tailored to break language barriers and foster a more inclusive school environment.",
          },
          {
            bold: "",
            text: "PD for Spanish teachers offering advanced teaching strategies and resources that align with state education standards. Our solution to your challenges.",
          },
        ],
        slogan1: "",
        slogan2: "",
        slogan3: "",
        button: "Read more",
      },
      ourSolutionsIB: {
        tag: "Our solution",
        title1: "Courses for IB Schools",
        solutions: [
          {
            bold: "",
            text: "Expertly designed language courses that align with the IB curriculum (PYP, MYP and DP) that promote international mindset and global competence.",
          },
          {
            bold: "",
            text: "Professional development programs for language teachers offer advanced teaching strategies that align with IB standards, enhancing both educator effectiveness and student outcomes.",
          },
          {
            bold: "",
            text: "Support and professional development for the school community tailored for school staff and English-speaking parents to break down communication barriers and foster an inclusive school environment.",
          },
        ],
        slogan1: "",
        slogan2: "",
        slogan3: "",
        button: "Read more",
      },
      ourSolutionsHigherEd: {
        tag: "Our solution",
        title1: "Courses for Higher Education",
        solutions: [
          {
            bold: "",
            text: "Comprehensive Spanish curriculum and specialized programs which are crafted to enhance language proficiency and critical thinking preparing students for academic success.",
          },
          {
            bold: "",
            text: "Specialized programs and cultural enrichment tailored to specific academic and professional needs such as Spanish for Specific Purposes and Diversity and Culture.",
          },
          {
            bold: "",
            text: "Professional development for faculty and staff designed for university-level Spanish instructors and staff, focusing on advanced teaching strategies and up-to-date resources.",
          },
        ],
        slogan1: "",
        slogan2: "",
        slogan3: "",
        button: "Read more",
      },
      ourSolutionsPublic: {
        tag: "Our solution",
        title1: "Courses Offered for the public sector",
        solutions: [
          {
            bold: "",
            text: "Comligo provides comprehensive language programs, including Spanish for Diversity, Cultural Workshops and Spanish for Specific Purposes with flexible schedules and interactive learning experiences.",
          },
          {
            bold: "",
            text: "We also offer Spanish conversation clubs that promote real-life language practice and strengthen community ties.",
          },
          {
            bold: "",
            text: "Our summer camps provide a unique, game-based learning experience for different age groups, with flexible durations ranging from 2 to 8 weeks",
          },
        ],
        slogan1: "",
        slogan2: "",
        slogan3: "",
        button: "Read more",
      },
      ourSolutionsPrivate: {
        tag: "Our solution",
        title1: "Courses Offered for the private sector & NGOs",
        solutions: [
          {
            bold: "",
            text: "Comligo provides comprehensive language programs, including Spanish for Diversity, Cultural Workshops, and Spanish for Specific Purposes with flexible schedules and interactive learning experiences.",
          },
          {
            bold: "",
            text: "We also offer Spanish conversation clubs that promote real-life language practice and strengthen community and organizational ties.",
          },
          {
            bold: "",
            text: "Our summer camps provide a unique, game-based learning experience for different age groups, with flexible durations ranging from 2 to 8 weeks.",
          },
        ],
        slogan1: "",
        slogan2: "",
        slogan3: "",
        button: "Read more",
      },
      ourSolutionsCorporate: {
        tag: "Our solution",
        title1: "Courses offered for Corporate Organizations",
        solutions: [
          {
            bold: "",
            text: "Introductory and intermediate Spanish courses designed for employees at various proficiency levels, these courses build a strong foundation in Spanish.",
          },
          {
            bold: "",
            text: "Spanish for diversity and inclusion focused on promoting cultural awareness and inclusivity within corporate environments.",
          },
          {
            bold: "",
            text: "Spanish for specific professions tailored for healthcare professionals and business contexts, these courses provide relevant language skills for specialized fields.",
          },
        ],
        slogan1: "",
        slogan2: "",
        slogan3: "",
        button: "Read more",
      },
      yourChallengesK12: [
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Teachers Shortage",
          descTop:
            "Difficulty finding qualified Spanish teachers for all courses and groups, causing gaps in the provision of classes to students.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Availability of Teachers",
          descBottom:
            "Access a network of highly qualified Spanish instructors to ensure full coverage and quality teaching.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Communication Barriers",
          descTop:
            "Staff struggles with effective daily communication in Spanish, impacting interactions with Spanish-speaking students and families.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Ease of Communication",
          descBottom:
            "Comprehensive courses to enhance staff’s Spanish communication skills for better daily interactions with their Hispanic counterparts.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Lack of Practice",
          descTop:
            "Students lack sufficient real-life practice on their Spanish, limiting their Spanish language proficiency and application.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Interactive Learning",
          descBottom:
            "Engaging lessons with practical exercises to boost students' real-life Spanish skills and confidence.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Parental challenges",
          descTop:
            "English-speaking parents find it difficult to assist with the Spanish homework for their kids, affecting student support.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Family Support",
          descBottom:
            "Workshops and resources designed to help parents effectively support their children’s Spanish learning at home.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Outdated skills",
          descTop:
            "The lack of ongoing professional development for Spanish teachers, impacting their teaching effectiveness and up-to-date skills.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Advanced Training",
          descBottom:
            "Professional development resources that continuously enhance educators' skills and teaching practices.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Culture Gaps",
          descTop:
            "Students have limited exposure to Hispanic cultures, hindering their understanding and appreciation of cultural contexts.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Bridge the Gaps",
          descBottom:
            "Courses that are integrated with cultural lessons to deepen students' understanding of Hispanic cultures and contexts.",
        },
      ],
      yourChallengesIB: [
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Teachers Shortage",
          descTop:
            "Difficulty finding qualified Spanish teachers for all courses and groups, causing gaps in the provision of classes to students.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Availability of Teachers",
          descBottom:
            "Access a network of highly qualified Spanish instructors to ensure full coverage and quality teaching.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Communication Barriers",
          descTop:
            "Staff struggles with effective daily communication in Spanish, impacting interactions with Spanish-speaking students and families.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Ease of Communication",
          descBottom:
            "Comprehensive courses to enhance staff’s Spanish communication skills for better daily interactions with their Hispanic counterparts.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Lack of Practice",
          descTop:
            "Students lack sufficient real-life practice on their Spanish, limiting their Spanish language proficiency and application.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Interactive Learning",
          descBottom:
            "Engaging lessons with practical exercises to boost students' real-life Spanish skills and confidence.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Parental challenges",
          descTop:
            "English-speaking parents find it difficult to assist with the Spanish homework for their kids, affecting student support.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Family Support",
          descBottom:
            "Workshops and resources designed to help parents effectively support their children’s Spanish learning at home.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Outdated skills",
          descTop:
            "The lack of ongoing professional development for Spanish teachers, impacting their teaching effectiveness and up-to-date skills.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Advanced Training",
          descBottom:
            "Professional development resources that continuously enhance educators' skills and teaching practices.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Culture Gaps",
          descTop:
            "Students have limited exposure to Hispanic cultures, hindering their understanding and appreciation of cultural contexts.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Bridge the Gaps",
          descBottom:
            "Courses that are integrated with cultural lessons to deepen students' understanding of Hispanic cultures and contexts.",
        },
      ],
      yourChallengesHigherEducation: [
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Unspecificialized Programs",
          descTop:
            "Difficulty finding Spanish language programs that precisely meet your institution’s specific needs and objectives.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Customized Solutions",
          descBottom:
            "Developed tailored Spanish language programs that perfectly align with your institution’s unique requirements and goals.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Untailored Classes",
          descTop:
            "Struggling to provide effective Spanish training solutions tailored for the staff of our corporate clients.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Specialized Training",
          descBottom:
            "Offered  and  targeted Spanish classes designed for corporate environments to enhance communication skills and business interactions.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Decline in Enrollment",
          descTop:
            "Facing difficulties in increasing student enrollment and participation in our Spanish language courses and programs.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Engaging Courses",
          descBottom:
            "Focused on making Spanish courses more attractive and engaging to boost student enrollment and participation.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Growth Limitation",
          descTop:
            "Limited opportunities available for staff to advance their professional development and career growth in Spanish education.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Development Programs",
          descBottom:
            "Provided advanced training and professional development workshops to enhance staff skills and support career advancement.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Teacher Shortage",
          descTop:
            "Difficulty in recruiting and retaining qualified Spanish instructors, impacting the quality and consistency of courses.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Recruitment Network",
          descBottom:
            "Access to an extensive network to find and retain highly qualified Spanish instructors, ensuring quality course delivery.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Community Challenges",
          descTop:
            "Managing and supporting grant-backed community Spanish initiatives effectively is proving to be a significant hurdle.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Program Support",
          descBottom:
            "Offer tailored support and resources to enhance the effectiveness and impact of community-focused Spanish programs.",
        },
      ],
      yourChallengesPublic: [
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Limited Enrichment",
          descTop:
            "Difficulty in offering diverse enrichment programs for young students, impacting engagement and learning outcomes.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Engaging Programs",
          descBottom:
            "Provided comprehensive enrichment programs tailored to boost student engagement and academic achievement.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Unspecialized Programs",
          descTop:
            "Struggling to find specialized Spanish courses that align with your organization’s goals and community needs.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Tailored Courses",
          descBottom:
            "Spanish for Diversity and Specific Purposes courses that are customized and offered to meet the unique needs of your community.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Cultural Gaps",
          descTop:
            "Limited opportunities to promote multicultural understanding within the community.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Cultural Workshops",
          descBottom:
            "Facilitated cultural workshops that enhance community awareness and foster inclusivity.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Outdated Skills",
          descTop:
            "Community and staff have limited opportunities to enhance and practice their Spanish language skills effectively.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Interactive Clubs",
          descBottom:
            "Established Spanish conversation clubs that promote real-life language practice and skill development.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Seasonal Engagement",
          descTop:
            "Difficulty maintaining community engagement during summer periods, leading to reduced participation.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Summer Camps",
          descBottom:
            "Flexible summer camps offered with interactive learning activities, designed to keep patrons engaged and learning.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Unspecialized Programs",
          descTop:
            "Struggle to find language programs tailored to specific organizational needs and objectives.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Tailored Courses",
          descBottom:
            "Customized Spanish courses designed to meet your unique professional and organizational goals.",
        },
      ],
      yourChallengesPrivate: [
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Limited Enrichment",
          descTop:
            "Difficulty in offering diverse enrichment programs for young students, impacting engagement and learning outcomes.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Engaging Programs",
          descBottom:
            "Provided comprehensive enrichment programs tailored to boost student engagement and academic achievement.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Unspecialized Programs",
          descTop:
            "Struggling to find specialized Spanish courses that align with your organization’s goals and community needs.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Tailored Courses",
          descBottom:
            "Spanish for Diversity and Specific Purposes courses that are customized and offered to meet the unique needs of your community.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Cultural Gaps",
          descTop:
            "Limited opportunities to promote multicultural understanding within the community.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Cultural Workshops",
          descBottom:
            "Facilitated cultural workshops that enhance community awareness and foster inclusivity.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Outdated Skills",
          descTop:
            "Community and staff have limited opportunities to enhance and practice their Spanish language skills effectively.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Interactive Clubs",
          descBottom:
            "Established Spanish conversation clubs that promote real-life language practice and skill development.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Seasonal Engagement",
          descTop:
            "Difficulty maintaining community engagement during summer periods, leading to reduced participation.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Summer Camps",
          descBottom:
            "Flexible summer camps offered with interactive learning activities, designed to keep patrons engaged and learning.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Unspecialized Programs",
          descTop:
            "Struggle to find language programs tailored to specific organizational needs and objectives.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Tailored Courses",
          descBottom:
            "Customized Spanish courses designed to meet your unique professional and organizational goals.",
        },
      ],
      yourChallengesCorporate: [
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Unspecialized Programs",
          descTop:
            "Struggle to find language programs tailored to specific organizational needs and objectives.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Tailored Courses",
          descBottom:
            "Customized Spanish courses designed to meet your unique professional and organizational goals.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Outdated skills",
          descTop:
            "Staff have limited opportunities to enhance and practice their Spanish language skills effectively.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Professional Development",
          descBottom:
            "Provide targeted courses to foster continuous learning and professional growth.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Cultural Awareness",
          descTop:
            "Difficulty promoting multicultural understanding and inclusivity within the workplace.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Inclusive Training",
          descBottom:
            "Courses designed to enhance cultural awareness and foster a more inclusive work environment.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Engagement Issues",
          descTop:
            "Staff feels disengaged during traditional language learning courses.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Interactive Learning",
          descBottom:
            "Engaging and flexible courses designed to keep your staff motivated and actively involved.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Communication Gaps",
          descTop:
            "Challenges in connecting effectively with Spanish-speaking counterparts.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Enhanced Communication",
          descBottom:
            "Language training that boosts confidence in interacting with Spanish-speaking colleagues and clients.",
        },
        {
          iconTop: "mdi mdi-alert-outline",
          titleTop: "Rigid Learning",
          descTop:
            "Traditional options lack flexibility and opportunities for self-study.",
          middleIcon: "mdi mdi-chevron-double-down",
          iconBottom: "mdi mdi-lightbulb-outline",
          titleBottom: "Flexible Options",
          descBottom:
            "Courses that offer flexible schedules and self-study opportunities, tailored to busy professionals.",
        },
      ],
      includedInOurCourses: [
        {
          title: "Interactive Lessons",
          description: "Enjoy engaging lessons that make",
          description1: "learning Spanish fun and effective ",
          description2: "with practical exercises.",
        },
        {
          title: "Materials for self study",
          description: "Our courses provide a wide range of",
          description1: "materials designed for self-study to",
          description2:
            "enhance your learning experience. These resources include comprehensive textbooks,workbooks,audio files,cultural articles etc.",
        },
        {
          title: "Native-Speaking Instructors",
          description: "Learn from experienced,",
          description1: "native Spanish speakers who",
          description2: "provide personalized instruction.",
        },
        {
          title: "Cultural Insights",
          description: "Discover Spanish-speaking cultures",
          description1: "through music, movies, and traditions",
          description2: "to enhance your learning.",
        },
        {
          title: "Flexible Learning Options",
          description: "Choose from online classes,",
          description1: "self-paced modules, and live sessions",
          description2: "that fit your schedule.",
        },
        {
          title: "Supportive Community",
          description: "Join a vibrant group of",
          description1: "learners with access to study",
          description2: "sessions, forums, and events.",
        },
        {
          title: "Personalized Feedback",
          description: "Get tailored feedback and support",
          description1: "to help you achieve",
          description2: "your learning goals.",
        },
        {
          title: "Real-Life Practice",
          description: "Work on projects and exercises that",
          description1: "simulate real-life situations to",
          description2: "practice your Spanish skills.",
        },
      ],
      trustedCompanies: [
        "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/portland.png?alt=media&token=5f0bb0f8-9bb0-408a-b7c2-b7b3a021fc15",
        "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/latino.png?alt=media&token=87d8e5a2-9559-4e40-b9b3-8d272df91989",
        "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/longbeach.png?alt=media&token=a5cf6049-22e1-4a79-ae29-25e4f2d8109e",
        "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/loreal.png?alt=media&token=238d6c5d-0b3b-4aaf-86d7-e3303575035f",
        "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/canal.png?alt=media&token=4732b976-315b-422e-bae4-5c0a3eae41b2",
        "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/deloitte.png?alt=media&token=0c7c41c5-c64b-47cc-9c0e-ea77c478a98e",
        "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/prospect.png?alt=media&token=2ea4a829-7806-48e0-bb5a-cacbbd0c8f8e",
        "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/arroyo.png?alt=media&token=f46e4846-6f01-47df-be74-04d33bc3f697",
        "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/LCH.png?alt=media&token=d7c0590e-b846-4698-aaee-e0e3f459a297",
        "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/HSBC.png?alt=media&token=eb1b3507-4e5a-4b62-8f7a-220effc2975b",
      ],
      homepageCourses: [
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/article1.png?alt=media&token=93a07866-f775-446d-8846-7b9f0503241e",
          subText: "For Adults",
          title: "Beginner Spanish",
          p1: "Start with the basics and make learning Spanish fun and easy.",
          list: [
            {
              bold: "Foundation Building:",
              normal:
                "Begin with the basics to build a strong base for new learners.",
            },
            {
              bold: "Easy and Fun:",
              normal: "Enjoy a simple and engaging learning experience.",
            },
            {
              bold: "Practical Skills:",
              normal:
                "Learn useful phrases and vocabulary for everyday conversations.",
            },
            {
              bold: "Interactive Practice:",
              normal:
                "Participate in interactive exercises to gain confidence.",
            },
          ],
          tag: "Beginner Spanish",
          button: "Read more",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/article2.png?alt=media&token=17d98973-d56e-4121-9aec-d038d8b12531",
          subText: "For Adults",
          title: "Intermediate Spanish",
          p1: "Perfect for those with basic Spanish skills who want to improve their fluency.",
          list: [
            {
              bold: "Build on Basics:",
              normal: "Advance your Spanish with more grammar and vocabulary.",
            },
            {
              bold: "Expand Proficiency:",
              normal: "Gain confidence in handling more complex conversations.",
            },
            {
              bold: "Real-World Use:",
              normal: "Apply your skills in business and social settings.",
            },
            {
              bold: "Focused Support:",
              normal:
                "Get help to overcome language challenges and improve your skills.",
            },
          ],
          tag: "Intermediate Spanish",
          button: "Read more",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/article3.png?alt=media&token=5e6dbbf1-c3f5-4e16-82a7-f01ebf7f461f",
          subText: "For Adults",
          title: "Advanced Spanish",
          p1: "Great for improving your skills and handling complex conversations.",
          list: [
            {
              bold: "Skill Refinement:",
              normal: "Sharpen your advanced Spanish skills.",
            },
            {
              bold: "Complex Conversations:",
              normal: "Talk confidently about detailed topics.",
            },
            {
              bold: "Cultural Insights:",
              normal: "Learn more about Spanish culture and nuances.",
            },
            {
              bold: "Professional Use:",
              normal: "Communicate better in work and business settings.",
            },
          ],
          tag: "Advanced Spanish",
          button: "Read more",
        },
      ],
      comprehensiveCourses: [
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/article1.png?alt=media&token=93a07866-f775-446d-8846-7b9f0503241e",
          subText: "For Adults",
          title: "Beginner Spanish",
          p1: "Start with the basics and make learning Spanish fun and easy.",
          list: [
            {
              bold: "Foundation Building:",
              normal:
                "Begin with the basics to build a strong base for new learners.",
            },
            {
              bold: "Easy and Fun:",
              normal: "Enjoy a simple and engaging learning experience.",
            },
            {
              bold: "Practical Skills:",
              normal:
                "Learn useful phrases and vocabulary for everyday conversations.",
            },
            {
              bold: "Interactive Practice:",
              normal:
                "Participate in interactive exercises to gain confidence.",
            },
          ],
          tag: "Beginner Spanish",
          button: "Read more",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/article2.png?alt=media&token=17d98973-d56e-4121-9aec-d038d8b12531",
          subText: "For Adults",
          title: "Intermediate Spanish",
          p1: "Perfect for those with basic Spanish skills who want to improve their fluency.",
          list: [
            {
              bold: "Build on Basics:",
              normal: "Advance your Spanish with more grammar and vocabulary.",
            },
            {
              bold: "Expand Proficiency:",
              normal: "Gain confidence in handling more complex conversations.",
            },
            {
              bold: "Real-World Use:",
              normal: "Apply your skills in business and social settings.",
            },
            {
              bold: "Focused Support:",
              normal:
                "Get help to overcome language challenges and improve your skills.",
            },
          ],
          tag: "Intermediate Spanish",
          button: "Read more",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/article3.png?alt=media&token=5e6dbbf1-c3f5-4e16-82a7-f01ebf7f461f",
          subText: "For Adults",
          title: "Advanced Spanish",
          p1: "Great for improving your skills and handling complex conversations.",
          list: [
            {
              bold: "Skill Refinement:",
              normal: "Sharpen your advanced Spanish skills.",
            },
            {
              bold: "Complex Conversations:",
              normal: "Talk confidently about detailed topics.",
            },
            {
              bold: "Cultural Insights:",
              normal: "Learn more about Spanish culture and nuances.",
            },
            {
              bold: "Professional Use:",
              normal: "Communicate better in work and business settings.",
            },
          ],
          tag: "Advanced Spanish",
          button: "Read more",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/article1.png?alt=media&token=93a07866-f775-446d-8846-7b9f0503241e",
          subText: "For Adults",
          title: "Beginner Spanish",
          p1: "Start with the basics and make learning Spanish fun and easy.",
          list: [
            {
              bold: "Foundation Building:",
              normal:
                "Begin with the basics to build a strong base for new learners.",
            },
            {
              bold: "Easy and Fun:",
              normal: "Enjoy a simple and engaging learning experience.",
            },
            {
              bold: "Practical Skills:",
              normal:
                "Learn useful phrases and vocabulary for everyday conversations.",
            },
            {
              bold: "Interactive Practice:",
              normal:
                "Participate in interactive exercises to gain confidence.",
            },
          ],
          tag: "Beginner Spanish",
          button: "Read more",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/article2.png?alt=media&token=17d98973-d56e-4121-9aec-d038d8b12531",
          subText: "For Adults",
          title: "Intermediate Spanish",
          p1: "Perfect for those with basic Spanish skills who want to improve their fluency.",
          list: [
            {
              bold: "Build on Basics:",
              normal: "Advance your Spanish with more grammar and vocabulary.",
            },
            {
              bold: "Expand Proficiency:",
              normal: "Gain confidence in handling more complex conversations.",
            },
            {
              bold: "Real-World Use:",
              normal: "Apply your skills in business and social settings.",
            },
            {
              bold: "Focused Support:",
              normal:
                "Get help to overcome language challenges and improve your skills.",
            },
          ],
          tag: "Intermediate Spanish",
          button: "Read more",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/article3.png?alt=media&token=5e6dbbf1-c3f5-4e16-82a7-f01ebf7f461f",
          subText: "For Adults",
          title: "Advanced Spanish",
          p1: "Great for improving your skills and handling complex conversations.",
          list: [
            {
              bold: "Skill Refinement:",
              normal: "Sharpen your advanced Spanish skills.",
            },
            {
              bold: "Complex Conversations:",
              normal: "Talk confidently about detailed topics.",
            },
            {
              bold: "Cultural Insights:",
              normal: "Learn more about Spanish culture and nuances.",
            },
            {
              bold: "Professional Use:",
              normal: "Communicate better in work and business settings.",
            },
          ],
          tag: "Advanced Spanish",
          button: "Read more",
        },
      ],
      testimonials: [
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/testimonials3.png?alt=media&token=e410fac4-5dd8-4bf9-966d-72c5e1583387",
          text: "Throughout the courses provided by Comligo, our students have not only improved their Spanish language proficiency but have also become more culturally aware and competent.",
          name: "Principal, High school, NY",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/testimonials1.png?alt=media&token=8e76bf8f-07ed-4b18-b4ac-3a2288c4da4e",
          text: "Comligo educators create a supportive and inclusive learning environment that encourages participants to push their boundaries and excel in their language acquisition journey.",
          name: "Superintendent, School district, WA",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/testimonials2.png?alt=media&token=983dc065-9cfd-4326-adfa-7e539ec53b44",
          text: "We are looking forward to partnering again with Comligo again this upcoming school year and would recommend Comligo to anyone looking to bring this learning opportunity to their classrooms.",
          name: "Head of School, Charter School, TX",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/testimonials3.png?alt=media&token=e410fac4-5dd8-4bf9-966d-72c5e1583387",
          text: "We have been very pleased with the classes and the professionalism of the Comligo instructors.The staff was very pleased with the classes, the instructors and the overall quality.",
          name: "Chief Operations Officer, Non-profit, TN",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/testimonials1.png?alt=media&token=8e76bf8f-07ed-4b18-b4ac-3a2288c4da4e",
          text: "Comligo has been an invaluable partner to our organization. Their communication was personalized, ensuring that every aspect of their service was tailored to meet our specific requirements.",
          name: "HR Manager, Non-profit, CA",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/testimonials2.png?alt=media&token=983dc065-9cfd-4326-adfa-7e539ec53b44",
          text: "Comligo Spanish has been very helpful to me with learning how to speak Spanish via their interactive, engaging Spanish classes. I look forward to continuing my partnership with Comligo Spanish.",
          name: "CEO, Healthcare Group, PA",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/testimonials3.png?alt=media&token=e410fac4-5dd8-4bf9-966d-72c5e1583387",
          text: "Milena is a wonderful teacher! She encourages us and helps us learn. I am grateful to her for helping me grow my Spanish skills so much in the last year and a half.",
          name: "Staff, School District, WA",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/testimonials1.png?alt=media&token=8e76bf8f-07ed-4b18-b4ac-3a2288c4da4e",
          text: "I am extremely pleased with my progress in the Spanish language. Gabriela has been instrumental in boosting my confidence to use it daily at work.",
          name: "Staff, School District, OR",
        },
      ],
      empoweringLanguage: [
        {
          icon: "mdi mdi-heart-outline",
          title: "Boosting",
          title1: " Student Interest",
          description: "Building a lively environment",
          description1: "that sparks students'",
          description2: "desire to learn Spanish.",
        },
        {
          icon: "mdi mdi-web",
          title: "Cultural",
          title1: "Understanding",
          description: "Engaging students with",
          description1: "real experiences alongside",
          description2: "native Spanish teachers.",
        },
        {
          icon: "mdi mdi-chart-line",
          title: "Flexible",
          title1: "Programs",
          description: "Offering adaptable",
          description1: "language programs for",
          description2: "any institution's size.",
        },
        {
          icon: "mdi mdi-cog-outline",
          title: "Teacher",
          title1: "Support",
          description: "Equipping teachers and staff",
          description1: "with vital tools for effective",
          description2: "Spanish communication.",
        },
      ],
      homeCaseStudies: [
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/successStoriesImg.png?alt=media&token=6f961117-63f9-4325-a20b-0c4bdab28dc7",
          subText: "Case Studies",
          title:
            "How Comligo Enhanced Language Learning for XYZ School District",
          description:
            "Explore how Comligo’s tailored Spanish programs transformed language education at XYZ School District. Learn how our interactive, game-based approach improved student engagement and proficiency.",
          tag: "For K-12 schools",
          button: "Read more",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/successStoriesImg.png?alt=media&token=6f961117-63f9-4325-a20b-0c4bdab28dc7",
          subText: "Case Studies",
          title:
            "How Comligo Enhanced Language Learning for XYZ School District",
          description:
            "Explore how Comligo’s tailored Spanish programs transformed language education at XYZ School District. Learn how our interactive, game-based approach improved student engagement and proficiency.",
          tag: "For K-12 schools",
          button: "Read more",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/successStoriesImg.png?alt=media&token=6f961117-63f9-4325-a20b-0c4bdab28dc7",
          subText: "Case Studies",
          title:
            "How Comligo Enhanced Language Learning for XYZ School District",
          description:
            "Explore how Comligo’s tailored Spanish programs transformed language education at XYZ School District. Learn how our interactive, game-based approach improved student engagement and proficiency.",
          tag: "For K-12 schools",
          button: "Read more",
        },
      ],
      homepageInsights: [
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/insights1.png?alt=media&token=f101783e-dcf9-4ddd-bf97-fe41727ad565",
          subText: "Online learning",
          title: "How AI is Revolutionizing Spanish Language Education",
          p1: "Explore the transformative power of AI in language learning. Learn how Comligo’s AI-driven tools personalize your Spanish learning experience and help you achieve fluency faster.",
          author: "Author Name",
          date: "12 July 2023",
          button: "Read more",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/insights2.png?alt=media&token=ca25dc02-e564-4e3c-8e3f-b52410b1862b",
          subText: "Spanish language and culture",
          title:
            "Comligo’s 2024 Impact Report: Advancing Global Language Education",
          p1: "Delve into our latest impact report and see the strides Comligo has made in advancing language education worldwide. Get insights into our successes and future goals.",
          author: "Author Name",
          date: "12 July 2023",
          button: "Read more",
        },
        {
          image:
            "https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/insights3.png?alt=media&token=3c840a03-ac63-4906-940e-a63be3490133",
          subText: "Spanish language and culture",
          title: "Interactive Strategies to Boost Your Spanish Learning",
          p1: "Unlock the secrets to mastering Spanish with engaging, game-based learning methods. Discover how interactive activities can enhance your language skills and make learning fun.",
          author: "Author Name",
          date: "12 July 2023",
          button: "Read more",
        },
      ],
      collaborationsTimelineK12: [
        {
          color: "#E05025",
          title: "Setup & Communication",
          desc: "The Comligo academic coordinator sets up the classes and shares the necessary details with the organization.",
        },
        {
          color: "#E05025",
          title: "Teacher & Resources",
          desc: "Assignment of native Spanish teachers and provision of access to a dedicated site with all course materials activities.",
        },
        {
          color: "#E05025",
          title: "Launch & Support",
          desc: "Start classes with the assigned instructor and give access to a forum for ongoing communication between students and teacher.",
        },
      ],
      collaborationsTimelineIB: [
        {
          color: "#E05025",
          title: "Setup & Communication",
          desc: "The Comligo academic coordinator works closely with your school to establish a tailored program ensuring seamless integration with your existing curriculum.",
        },
        {
          color: "#E05025",
          title: "Teacher & Resources",
          desc: "Assignment of experienced native language instructors and access to a dedicated site with comprehensive course materials and activities.",
        },
        {
          color: "#E05025",
          title: "Launch & Support",
          desc: "Begin classes with the assigned instructor and enjoy ongoing support through a dedicated communication forum for students and teachers.",
        },
      ],
      collaborationsTimelineHigherEducation: [
        {
          color: "#E05025",
          title: "Setup & Communication",
          desc: "Collaborate with Comligo’s academic coordinators to design a tailored program that integrates seamlessly with your existing curriculum.",
        },
        {
          color: "#E05025",
          title: "Faculty & Resources",
          desc: "Assign experienced, native language instructors and provide access to a comprehensive suite of course materials and activities.",
        },
        {
          color: "#E05025",
          title: "Launch & Support",
          desc: "Initiate classes with assigned instructors and benefit from ongoing support through dedicated communication channels for students and faculty.",
        },
      ],
      collaborationsTimelinePublic: [
        {
          color: "#E05025",
          title: "Setup & Communication",
          desc: "The Comligo academic coordinator establishes the classes and shares relevant details with your organization.",
        },
        {
          color: "#E05025",
          title: "Teacher & Resources",
          desc: "Assign native Spanish teachers and provide access to a dedicated site with all course materials and activities.",
        },
        {
          color: "#E05025",
          title: "Launch & Support",
          desc: "Start classes with the assigned instructor, and give access to a forum for ongoing communication between patrons and the teacher.",
        },
      ],
      collaborationsTimelinePrivate: [
        {
          color: "#E05025",
          title: "Setup & Communication",
          desc: "The Comligo academic coordinator establishes the classes and shares relevant details with your organization.",
        },
        {
          color: "#E05025",
          title: "Teacher & Resources",
          desc: "Assign native Spanish teachers and provide access to a dedicated site with all course materials and activities.",
        },
        {
          color: "#E05025",
          title: "Launch & Support",
          desc: "Start classes with the assigned instructor, and give access to a forum for ongoing communication between patrons and the teacher.",
        },
      ],
      collaborationsTimelineCorporate: [
        {
          color: "#E05025",
          title: "Setup & Communication",
          desc: "The Comligo academic coordinator establishes the classes and shares relevant details with your organization.",
        },
        {
          color: "#E05025",
          title: "Teacher & Resources",
          desc: "Assign native Spanish teachers and provide access to a dedicated site with all course materials and activities.",
        },
        {
          color: "#E05025",
          title: "Launch & Support",
          desc: "Start classes with the assigned instructor, and give access to a forum for ongoing communication between patrons and the teacher.",
        },
      ],
      pricingPlans: {
        tag: "Pricing Plans",
        title: "Choose the Perfect Plan for Your Needs",
        desc: "Whether you're looking for personalized instruction or collaborative learning, we have a plan that fits your requirements.",
        plans: [
          {
            title: "Individual Classes",
            subTitle: "For individuals and organizations",
            paragraph:
              "Get personalized attention and a customized learning experience with our individual classes.",
            includedTitle: "What's Included:",
            included: [
              "One-on-one sessions with a qualified Spanish instructor",
              "Flexible scheduling to accommodate your availability",
              "Customized curriculum aligned with your learning goals",
              "Access to our online learning materials and resources",
              "Regular feedback and progress reports",
            ],
          },
          {
            title: "Group Classes",
            subTitle: "For organizations",
            paragraph:
              "Enjoy a collaborative and interactive learning environment with our group classes.",
            includedTitle: "What's Included:",
            included: [
              "Small group sessions led by a qualified Spanish instructor",
              "Scheduled classes at convenient times",
              "Engaging curriculum designed for group learning",
              "Access to our online learning materials and resources",
              "Opportunities for peer interaction and support",
            ],
          },
        ],
      },
      frequentlyAskedQuestions: {
        title: "Frequently Asked Questions",
        list: [
          {
            question: "What are the IT requirements for Comligo classes?",
            answer:
              "Very simple! A computer, internet connection and a browser. A headset with a microphone is recommended.",
          },
          {
            question: "Are Comligo classes 100% live?",
            answer:
              "Yes, all classes are taught live by a native teacher, with real-time interaction.",
          },
          {
            question:
              "Does Comligo offer other courses besides regular Spanish classes?",
            answer:
              "Yes! In addition to our regular Spanish language courses for K-12 students and adults, we offer a “Spanish for Diversity” course and various cultural courses.",
          },
          {
            question: "How does Comligo handle exams and grading?",
            answer:
              "We provide real-time feedback, tests and evaluations based on school standards, approved by departments of education.",
          },
          {
            question: "What qualifications do Comligo teachers have?",
            answer:
              "All teachers are native Spanish speakers with a master’s degree in teaching or Spanish.",
          },
          {
            question: "What curriculum does Comligo follow?",
            answer:
              "We follow ACTFL standards, incorporating authentic cultural materials, which are widely accepted across the United States, tailored to meet additional state-specific requirements.",
          },
          {
            question: "How are assignments managed?",
            answer:
              "Assignments are sent through Padlet, where students access and submit their work.",
          },
          {
            question: "What does a typical Comligo class look like?",
            answer:
              "Students join via a link, and our teachers lead interactive, live sessions using tools like Nearpod.",
          },
          {
            question: "How are student levels and groups arranged?",
            answer:
              "We perform level tests to ensure students are placed in the appropriate Spanish level and group students by age and proficiency.",
          },
          {
            question: "Can Comligo adapt its schedule to a school’s calendar?",
            answer:
              "Yes, we are flexible and can adjust our schedule to fit your school’s needs. One week’s notice is required for such changes.",
          },
          {
            question: "What is the maximum class size?",
            answer: "Our standard class size is up to 15 students.",
          },
          {
            question: "Can parents or schools meet with Comligo teachers?",
            answer:
              "Yes, meetings with teachers can be arranged to discuss student performance. Scheduling in advance is appreciated due to our teachers' busy schedules.",
          },
        ],
      },
      notification: {
        show: false,
        title: "",
        message: "",
        type: "",
      },
      dialog: false,
      courses: [],
      course: {},
      breadcrumbs: [],
      articles: [],
      article: {},
      eBooks: [],
      eBook: {},
      guides: [],
      guide: {},
      events: [],
      event: {},
      caseStudies: [],
      caseStudy: {},
      k12Providers: [],
      k12Provider: {},
      cookiePolicy: {},
      consent: {},
    };
  },
  getters,
  mutations,
  actions: {},
  modules: {},
});
